import Prism from 'prismjs';
import mermaid from 'mermaid';
import {getLibUrl, loadCdnStyle} from 'o365.modules.helpers.js';

export default {
    mounted(el, binding) {
        mermaid.initialize({ startOnLoad: false });
        
        let theme = binding.value?.themeOverride;

        if (!theme) {
            theme = document.querySelector('meta[name="o365-theme"]')?.getAttribute('content');
        }
        
        if (theme === 'dark') {
            loadCdnStyle('prismjs/Prism.TomorrowNight.min.css');
            loadCdnStyle('prismjs/prism-vsc-dark-plus.css');
        } else {
            loadCdnStyle('prismjs/Prism.min.css');
            loadCdnStyle('prismjs/prism-vs.css');
        }

        el.classList.add('line-numbers');

        mermaid.init({ noteMargin: 10 }, '.mermaid');

        Prism.highlightAllUnder(el);
    },

    updated(el) {      
        mermaid.init({ noteMargin: 10 }, '.mermaid');
        
        Prism.highlightAllUnder(el);
    },
}



